import React from "react"
import style from "./style.module.scss"

// export default () => {
//   return (
//     <div>
//       <a href="/" rel="noopener noreferrer">
//         <img src="/resources/img/logo.svg" alt="Partnr" />
//       </a>
//       <div className={style.menu__links}>
//         <a href="https://partnr.ai" target="_blank" rel="noopener noreferrer">
//           Site
//         </a>
//       </div>
//     </div>
//   )
// }

export default () => {
  return (
    <div className={style.menu}>
      <div className="op__block row d-flex align-items-center justify-content-end pt-5">
        <div className="col">
          <a href="/" rel="noopener noreferrer">
            <img
              src="/resources/img/logo.svg"
              alt="Partnr"
              style={{ height: "3rem" }}
            />
          </a>
        </div>
        <div className="col d-flex align-items-center justify-content-end">
          <a href="https://partnr.ai" target="_blank" rel="noopener noreferrer">
            Site
          </a>
        </div>
      </div>
    </div>
  )
}
