import React from "react"
import style from "./style.module.scss"

export default () => {
  return (
    <div className={style.footer}>
      <div className="op__block container-fluid text-center">
        <footer>
          <div>
            <span className={style.footer__logo}>PARTNR LTDA © 2020</span>
            <span>
              {" "}
              | <a href="#">Privacidade e Jurídico</a>
            </span>
          </div>
        </footer>
      </div>
    </div>
  )
}
